<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Workshop Calendar</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col sm="3">
                                <b-form-group label-for="month-year">
                                    <template v-slot:label>
                                        <span class="text-danger">*</span> Month:
                                    </template>
                                    <picker-of-dates type="month"
                                                     id="month-year"
                                                     v-model="monthYear"
                                                     format="MMMM yyyy"
                                                     placeholder="Select Month"
                                                     :state="fieldState('monthYear')"
                                                     clearable />
                                </b-form-group>
                            </b-col>
                            <b-col sm="6">
                                <b-form-group label-for="search-workshops" label="Workshop(s):">
                                    <b-input-group size="sm">
                                        <b-select id="search-workshops"
                                                  v-model="workshopNames"
                                                  :options="workshopOptions"
                                                  :select-size="8"
                                                  multiple />
                                        <b-input-group-addon>
                                            <b-button @click="workshopNames = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import _ from 'underscore';
import PickerOfDates from '@/components/shared/PickerOfDates';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        PickerOfDates
    }
})
export default class WorkshopCalendar extends Vue {

    monthYear = null;
    workshopNames = [];

    showState = false;

    fieldState(field) {
        // if required fields are added which default to an empty array (i.e.: date range)
        // please tweak this as needed.  (see: ApplicationStatistics.vue for an example)
        return this.showState ? !!this[field] : undefined;
    }


    get workshopOptions() {
        return _.map(this.$store.getters['common/workshops'], (ws) => {
            return {
                value: ws.value,
                text: ws.value
            }
        });
    }


    clearFilters() {
        this.monthYear = null;
        this.workshopNames = [];
        this.showState = false;
    }

    async getCsv() {
        if (!this.isReady()) {
            return;
        }
        try {
            await download('POST', '/api/v1/export/workshop-calendar', JSON.stringify({
                monthYear: this.monthYear,
                workshopNames: this.workshopNames
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async getPdf() {
        if (!this.isReady()) {
            return;
        }
        try {
            await download('POST', '/api/v1/report/workshop-calendar', JSON.stringify({
                monthYear: this.monthYear,
                workshopNames: this.workshopNames
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    isReady() {
        if (!this.monthYear) {
            this.showState = true;
            return false;
        }
        return true;
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Workshop Calendar', null, true));
        try {
            await this.$store.dispatch('common/loadWorkshops');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }
}
</script>


<style scoped>

</style>